<template>
	<div>
		<div>
			<label for="checkdepositconfiguration_bridgeaccount">{{ $t('invoice.bank_accounts') }}</label>
			<e-select
                v-model="bridges_accounts_selected"
                id="bridgeaccount_id"
                track-by="bridgeaccount_id"
                label="bridgeaccount_name"
                :placeholder="$t('check.selectionner_comptes')"
                :selectedLabel="$t('global.selected_label')"
                :options="bridge_accounts"
                :searchable="true"
                :loading="loading_accounts"
                :show-labels="false"
                group-values="accounts"
                group-label="accountingplan_label"
                :group-select="false"
                :multiple="true"
            >
                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
            </e-select>
		</div>
		<DateRangeV2
            :start.sync="start_date"
            :end.sync="end_date"
            @submit="loadTransactions"
            :ShowTodayButton="true"
            :dateSelect="true"
            :periodToShow="['personalise','jour','mois','mois_dernier']"
        />

        <CustomTable
            id_table="bridge_transactions"
            ref="bridge_transactions"
            :items="bridge_transactions"
            :busy.sync="table_busy"
            primaryKey="bridgetransaction_id"
            :hide_if_empty="true"
            :externSlotColumns="extern_slot_columns"
        >
			<template v-slot:[`custom-slot-cell(account_name)`]="{ data }">
				{{ data.bridge_account.bridge_bank.bridgebank_name }} - {{ data.bridge_account.bridgeaccount_name }}<br>
				{{ data.bridge_account.bridgeaccount_iban }}
			</template>
			<template v-slot:[`custom-slot-cell(invoices)`]="{ data }">
				<div v-for="(invoice, key) in data.invoices" :key="key">
					<a href="" class="" @click.prevent="quickPreview(invoice)">
					    {{ invoice.num }} <font-awesome-icon :icon="['fas', 'eye']" />
	                </a>
				</div>
			</template>
			<template v-slot:[`custom-slot-cell(tiers.tiers_rs)`]="{ data }">
				<router-link v-if="data.tiers" :to="{ name: 'tiersFiche', params: { tiers_id: data.tiers.tiers_id }}">{{ data.tiers.tiers_rs }}</router-link>
			</template>
    	</CustomTable>

        <b-modal size="xl" ref="modelPreview" hide-footer @hidden="pdf_url = null; base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="pdf_url != null" :src="pdf_url" height="1000px" width="100%" style="position:relative"></iframe>
            <iframe v-else-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>
	</div>
</template>

<script type="text/javascript">
    import OpenBank from "@/mixins/OpenBank.js"
    import Navigation from "@/mixins/Navigation.js"
    import Shutter from "@/mixins/Shutter.js"
    import Payment from "@/mixins/Payment.js"
    import Invoice from "@/mixins/Invoice.js"
	import ShutterInvoice from "@/mixins/shutters-manager/Invoice.js"
	import _cloneDeep from 'lodash/cloneDeep'

	export default {
		name: 'BankFlowList',
		mixins: [OpenBank, Navigation, Shutter, ShutterInvoice, Payment, Invoice],
		data () {
			return {
				extern_slot_columns: ['account_name', 'invoices', 'tiers.tiers_rs'],
                start_date: new Date(new Date().setMonth(new Date().getMonth() - 1)),
                end_date: new Date(),
				bridge_transactions: [],
				table_busy: false,
				events_tab: {
                    'TableAction::goToAssignTransaction': this.setupAssignationFlowTransaction,
                },
                loading_accounts: false,
                bridges_accounts_selected: [],
                bridge_accounts: [],
				pdf_url: null,
				base64: null
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading_accounts = true
				this.bridge_accounts = await this.getBridgeAccountsAccountingPlan()
				this.loading_accounts = false
			},

			async loadTransactions() {
				this.table_busy = true
				const accounts_ids = this.bridges_accounts_selected.map(account => account.bridgeaccount_id)
				if(accounts_ids.length == 0) {
					this.table_busy = false
					return false
				}
				this.bridge_transactions = await this.getBridgeTransaction(this.start_date.toDateInputValue(), this.end_date.toDateInputValue(), accounts_ids)
				this.table_busy = false
			},
			reload() {
				this.$refs.bridge_transactions.unselectAll()
				this.loadTransactions()
			},

            async quickPreview(invoice) {
            	this.pdf_url = null
            	this.base64 = null
            	
                this.$refs.modelPreview.show()
            	if(invoice.pdf_url) {
            		this.pdf_url = invoice.pdf_url
            	}
            	else if(invoice.checkdeposit_id) {
            		let checkdeposit_ids = {checkdeposit_ids: invoice.checkdeposit_id.toString()}
            		let retour = await this.printCheckDeposit(checkdeposit_ids, true)
            		if(retour) {
	                    this.base64 = retour
	                }
            	}
            	else {
	                let retour = await this.pdfInvoice([invoice.id], "", true)
	                if(retour) {
	                    this.base64 = retour
	                }
            	}
            }
		},

		components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}

</script>